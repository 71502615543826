<section class="bg-home personal-hero d-flex align-items-center background-effect overflow-hidden" id="home">
  <div class="container-fluid">
    <div class="bg-overlay bg-soft-primary jarallax" data-jarallax data-speed="0.5"
      style="background: url('assets/images/personal/hero-overlay.png') top"></div>

    <div class="container position-relative z-index-1">
      <div class="row mt-5">
        <div class="col">
          <div class="title-heading">
            <div class="row">
              <div class="col-lg-4 col-md-6 order-2 order-lg-1 mt-4 mt-sm-0">
                <div class="rounded personal-hero-para">
                  <h5 class="mb-3 sub-title">Web Designer & Developer</h5>

                  <p class="para-desc para mb-0">
                    Meticulous web developer with over <span class="font-weight-bold">4+ years</span> of front end
                    experience and passion for responsive website design
                  </p>

                  <div class="mt-4">
                    <a href="javascript: void(0);" [ngxScrollTo]="'#contact'" class="btn btn-primary">Hire me</a>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 offset-lg-4 col-md-6 order-1 order-lg-1">
                <h4 class="display-3 font-weight-bold mb-3">
                  M. Usman
                  <ngx-typed-js [strings]="['Ghani']" [shuffle]="true" [typeSpeed]="80" [loop]="true">
                    <span class="element typing"></span>
                  </ngx-typed-js>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="personal-overlay bg-overlay bg-primary-gradient-overlay"></div>
  <ul class="circles p-0 mb-0">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
</section>

<section class="section" id="about-me">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="row align-items-center">
          <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
              <div class="card-body p-0">
                <img src="assets/images/course/online/ab01.jpg" class="img-fluid" alt="work-image" />
                <div class="overlay-work bg-dark"></div>
                <div class="content">
                  <a href="javascript:void(0)" class="title text-white d-block font-weight-bold">Web Development</a>
                  <small class="text-light">IT & Software</small>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-6">
            <div class="row">
              <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/images/course/online/ab02.jpg" class="img-fluid" alt="work-image" />
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block font-weight-bold">Michanical
                        Engineer</a>
                      <small class="text-light">Engineering</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 mt-4 pt-2">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/images/course/online/ab03.jpg" class="img-fluid" alt="work-image" />
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block font-weight-bold">Chartered
                        Accountant</a>
                      <small class="text-light">C.A.</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
        <div class="ml-lg-4">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">About Me</h4>
            <p class="text-muted para-desc">
              Front-end developer with extensive
              <span class="text-primary font-weight-bold"> 4+ years </span>
              of experience and a strong focus on users, skilled in working with
              UX and design teams to organise the technical writing and
              implementation of functional requirements for websites and
              applications.
            </p>
            <p class="text-muted para-desc mb-0">
              Experienced in leveraging responsive web design (RWD) to construct
              multi-platform websites. devoted to creating adaptable websites
              and a staunch advocate of the mobile-first approach. well-versed
              in working with teams to build innovative websites that attract
              users and bring in money. well-versed in design standards and
              consumer preferences.
            </p>
          </div>

          <ul class="list-unstyled text-muted">
            <li class="mb-0">
              <span class="text-primary h4 mr-2"><i class="uim uim-check-circle"></i></span>Requirements Analysis
            </li>
            <li class="mb-0">
              <span class="text-primary h4 mr-2"><i class="uim uim-check-circle"></i></span>Project estimations
            </li>
            <li class="mb-0">
              <span class="text-primary h4 mr-2"><i class="uim uim-check-circle"></i></span>Team Collaboration
            </li>
            <li class="mb-0">
              <span class="text-primary h4 mr-2"><i class="uim uim-check-circle"></i></span>Project Lead
            </li>
          </ul>

          <div class="watch-video mt-4 pt-2">
            <a href="javascript: void(0);" [ngxScrollTo]="'#contact'" class="btn btn-primary mb-2">Let's Connect
              <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
            </a>
            <!-- <a (click)="openWindowCustomClass(content)" href="javascript:void(0);"
              class="video-play-icon watch text-dark mb-2 ml-2"><i
                class="mdi mdi-play play-icon-circle text-center d-inline-block mr-2 rounded-circle title-dark text-white position-relative play play-iconbar"></i>
              Watch Video !</a>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'jNTZpfXYJa4'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section bg-light" id="skills">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Tech Stack / Skills</h4>
          <p class="text-muted para-desc mx-auto mb-0">
            I'm a highly skilled developer. Experts in the skills listed below.
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div *ngFor="let skill of skills" class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="media key-feature align-items-center p-3 rounded shadow on-hover-bg-white">
          <img [src]="skill.logo" [alt]="skill.name" />
          <div class="media-body">
            <h4 class="title mb-0">{{skill.name}}</h4>
          </div>
        </div>
      </div>

      <div class="col-12 mt-4 pt-2 text-center">
        <a href="javascript:void(0)" [ngxScrollTo]="'#contact'" class="btn btn-primary">Want to discuss?</a>
      </div>
    </div>
  </div>
</section>

<section class="section" id="experience">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <div class="position-relative">
            <h4 class="title mb-4">Work Experience</h4>
          </div>
          <p class="text-muted mx-auto para-desc mb-0">
            I have extensive experience with all stages of the development cycle for dynamic web projects.
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-4 pt-2">
        <div class="timeline-page position-relative">
          <!-- <div class="timeline-item">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="duration date-label-left position-relative text-md-end">
                  <img src="assets/images/client/shree-logo.png" class="rounded shadow-sm avatar avatar-small" alt="" />
                  <h5 class="my-2">Self Employed</h5>
                  <small class="text-muted mb-0">2018-Present</small>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mt-4 mt-sm-0">
                <div class="event event-description-right float-left text-start">
                  <h6 class="title mb-1 text-capitalize">Sr. Front End Developer</h6>
                  <p class="timeline-subtitle mt-3 mb-0 text-muted">
                    Monitoring website performance and resolving front-end issues. Technical issues must be communicated
                    to the team and clients in a clear and understandable manner. Giving clients advice and
                    troubleshooting assistance. Keeping an organised workflow. Before and after deployment, testing is
                    performed to identify bugs and technical issues.
                  </p>
                </div>
              </div>
            </div>
          </div> -->

          <div class="timeline-item mt-5 pt-2">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2 mt-4 mt-sm-0">
                <div class="event event-description-left float-left text-end">
                  <h6 class="title mb-1 text-capitalize">
                    Sr. Front End Developer
                  </h6>
                  <p class="timeline-subtitle mt-3 mb-0 text-muted">
                    Examine the technical feasibility of UX and UI designs. Fix bugs and improve the application's
                    structure. Collaborated with members of the product team to implement new feature developments.
                    Monitoring website performance and troubleshooting front-end issues.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">
                <div class="duration duration-right position-relative">
                  <img src="assets/images/client/ssi.jpg" class="rounded shadow-sm avatar avatar-small" alt="" />
                  <h5 class="my-2">Strategic Systems International</h5>
                  <small class="text-muted mb-0">Big Company (500 - 1000)</small>
                </div>
              </div>
            </div>
          </div>

          <div class="timeline-item mt-5 pt-2">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 mt-4 mt-sm-0">
                <div class="duration date-label-left position-relative text-md-end">
                  <img src="assets/images/client/invozone.jpg" class="rounded shadow-sm avatar avatar-small" alt="" />
                  <h5 class="my-2">InvoZone Pvt Ltd.</h5>
                  <small class="text-muted mb-0">Medium Company (200 - 500)</small>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6 mt-4 mt-sm-0">
                <div class="event event-description-right float-left text-start">
                  <h6 class="title mb-1 text-capitalize">
                    Front End Developer
                  </h6>
                  <p class="timeline-subtitle mt-3 mb-0 text-muted">
                    Using web design and development principles to create reliable softwares. Bringing mock-ups to life
                    (Figma, AdobeXD, Illustrator, Photoshop, and so on). Working closely with the team to
                    support projects through all stages of completion. Creating responsive and usable websites and apps.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="timeline-item mt-5 pt-2">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2 mt-4 mt-sm-0">
                <div class="event event-description-left float-left text-end">
                  <h6 class="title mb-1 text-capitalize">
                    Jr. Front End Developer
                  </h6>
                  <p class="timeline-subtitle mt-3 mb-0 text-muted">
                    Using best practises to design and develop user interfaces. The latest front-end
                    technologies are used to create an adapting and responsive interface for modern internet
                    applications.
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">
                <div class="duration duration-right position-relative">
                  <img src="assets/images/client/websool.jpg" class="rounded shadow-sm avatar avatar-small" alt="" />
                  <h5 class="my-2">WebSool</h5>
                  <small class="text-muted mb-0">Small Company (11 - 50)</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- assets/images/personal/cta.jpg  bg-primary-gradient-overlay-->
<section class="py-5 jarallax" data-jarallax data-speed="0.5" style="background: url('assets/images/1.jpg')">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="text-light title-dark title mb-2">
            I Am Open To Project Contracts.
          </h4>
          <p class="text-light title-dark mx-auto mb-0 para-desc">
            Additionally, I have experience working on projects involving dynamic web development at all
            stages of the process.
          </p>
          <div class="mt-4 pt-2">
            <a href="javascript:void(0)" [ngxScrollTo]="'#contact'" class="btn btn-white mouse-down">Hire me
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-chevron-down fea icon-sm">
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section" id="projects">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-title mb-4 pb-2 text-center">
          <h4 class="title mb-4">My Work & Portfolio</h4>
          <p class="text-muted para-desc mb-0 mx-auto">
            I've worked on a variety of projects and products, including e-commerce, healthcare, transportation,
            management systems, portfolios, and payment systems, among others.
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div *ngFor="let project of projects" class="col-lg-6 col-md-6 col-12 mt-4 pt-2">
        <!-- <div class="card catagories overflow-hidden rounded shadow-lg border-0 cursor-pointer">
          <img [src]="project?.img" class="img-fluid" alt="">
          <div class="card-body border-top">
            <div class="content">
              <h5 class="mb-0">
                <a href="page-work-detail" class="text-dark title">{{project?.name}}</a>
              </h5>
              <h6 class="text-dark tag mb-0">{{project?.description}}</h6>
            </div>
          </div>
        </div> -->
        <div class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded shadow-lg p-3">
          <div class="card-body p-0">
            <img [src]="project?.img" class="img-fluid rounded" alt="work-image" />
            <div class="overlay-work bg-dark"></div>
            <div class="content">
              <h5 class="mb-0">
                <a href="page-work-detail" class="text-white title">{{project?.name}}</a>
              </h5>
              <h6 class="text-light tag mb-0">{{project?.description}}</h6>
            </div>
            <div class="icons text-center">
              <a [href]="project?.link" target="_blank"
                class="text-primary work-icon bg-white d-inline-block rounded-pill mfp-image">
                <i-feather name="camera" class="fea icon-sm"></i-feather>
              </a>
            </div>
          </div>
        </div>
      </div>


      <!-- <div class="col-12 mt-4 pt-2 text-center">
        <a href="page-work-modern" class="btn btn-outline-primary">See More <i class="mdi mdi-chevron-right"></i></a>
      </div> -->
    </div>
  </div>
</section>

<!-- <section class="section bg-light" id="clients">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Happy Clients</h4>
          <p class="text-muted para-desc mx-auto mb-0">
            Start working with
            <span class="text-primary font-weight-bold">Landrick</span> that can
            provide everything you need to generate awareness, drive traffic,
            connect.
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-4">
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/01.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="" />
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                </ul>
                <p class="text-muted mt-2">
                  "Very satisfied from Usman. Even if there were one
                  or two unplanned processes on my part, they were always implemented and completed to my complete
                  satisfaction."
                </p>
                <h6 class="text-primary">
                  - Felix Mergenthaler <small class="text-muted">German</small>
                </h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/03.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="" />
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                </ul>
                <p class="text-muted mt-2">
                  "Very good work. Good communication and fast deliver. Thanks a lot for taking care of this task."
                </p>
                <h6 class="text-primary">
                  - Carl Oliver
                  <small class="text-muted">Canada</small>
                </h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/04.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="" />
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                </ul>
                <p class="text-muted mt-2">
                  "The experience with Usman was remarkable.He gives various insight from his point of view which can be
                  an eye opener. It was an
                  outstanding service.

                </p>
                <h6 class="text-primary">
                  - John Smith
                  <small class="text-muted">UAE</small>
                </h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/01.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="" />
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                </ul>
                <p class="text-muted mt-2">
                  "Love the ambition and the excellent work. Always goes the extra mile and very supportive. Would
                  recommend!"
                </p>
                <h6 class="text-primary">
                  - Felix Mergenthaler <small class="text-muted">German</small>
                </h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/05.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="" />
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                </ul>
                <p class="text-muted mt-2">
                  "Thanks Mhd Usman for your professional work and for your reactivity and clear communication."
                </p>
                <h6 class="text-primary">
                  - Dean Tolle
                  <small class="text-muted">France</small>
                </h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/06.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="" />
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="mdi mdi-star text-warning"></i>
                  </li>
                </ul>
                <p class="text-muted mt-2">
                  "All went well. He understand the requirement really well and implement it accordingly."
                </p>
                <h6 class="text-primary">
                  - Jill Webb <small class="text-muted">USA</small>
                </h6>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section> -->

<section class="section" id="contact">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title mb-4 pb-2 text-center">
          <h4 class="title mb-4">Contacts</h4>
          <p class="text-muted para-desc mb-0 mx-auto">
            Let's connect via the platforms listed below to address your concerns.
          </p>
        </div>
      </div>
    </div>

    <div class="title-heading">
      <div class="row align-items-center">
        <div *ngFor="let contact of contacts" class="col-lg-4 col-md-6">
          <div class="media contact-detail align-items-center mt-3">
            <div class="icon mr-2">
              <img [src]="contact.logo" [alt]="contact.name" />
            </div>
            <div class="media-body content">
              <h4 class="title text-dark font-weight-bold mb-0">{{contact.name}}</h4>
              <a [href]="contact.link" class="text-primary">{{contact.number}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>