import { Component, OnInit } from "@angular/core"
import { OwlOptions } from "ngx-owl-carousel-o"

@Component({
  selector: "app-index-personal",
  templateUrl: "./index-personal.component.html",
  styleUrls: ["./index-personal.component.css"],
})
export class IndexPersonalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      900: {
        items: 2,
      },
    },
    nav: false,
  }

  skills = [
    { name: "Html5", logo: "assets/images/skills/html-5.png" },
    { name: "CSS3", logo: "assets/images/skills/css-3.png" },
    { name: "Sass / Scss", logo: "assets/images/skills/sass.png" },
    { name: "JavaScript", logo: "assets/images/skills/js.png" },
    { name: "TypeScript", logo: "assets/images/skills/typescript.png" },
    { name: "JQuery", logo: "assets/images/skills/jquery.png" },
    { name: "Bootstrap", logo: "assets/images/skills/bootstrap.png" },
    { name: "TailwindCSS", logo: "assets/images/skills/tailwindcss.png" },
    { name: "Wordpress Elementor", logo: "assets/images/skills/elementor.png" },
    {
      name: "Nicepage Website builder",
      logo: "assets/images/skills/nicepage.png",
    },
    { name: "Builder.io", logo: "assets/images/skills/builder.png" },
    { name: "Angular", logo: "assets/images/skills/angular.png" },
    { name: "Ngrx", logo: "assets/images/skills/ngrx.svg" },
    {
      name: "Angular Material",
      logo: "assets/images/skills/angular-material.svg",
    },
    { name: "React / React Native", logo: "assets/images/skills/react.png" },
    { name: "React Material", logo: "assets/images/skills/react-material.svg" },
    {
      name: "Styled-components",
      logo: "assets/images/skills/styled-components.svg",
    },
    { name: "Nextjs", logo: "assets/images/skills/nextjs.png" },
    { name: "Firebase / Firestore", logo: "assets/images/skills/firebase.png" },
    {
      name: "React Native with Firebase",
      logo: "assets/images/skills/react-native-firebase.png",
    },
    {
      name: "Redux Thunk / Saga / Toolkit",
      logo: "assets/images/skills/redux.png",
    },
    { name: "GitLab / Github", logo: "assets/images/skills/git.png" },
  ]

  contacts = [
    {
      name: "WhatsApp",
      number: "+971 55 874 1915",
      link: "https://api.whatsapp.com/send?phone=+971558741915",
      logo: "assets/images/client/whatsapp.png",
    },
    {
      name: "Skype",
      number: "Uthman01090",
      link: "skype:Uthman01090?chat",
      logo: "assets/images/client/skype.png",
    },
    {
      name: "Email",
      number: "muhammadusman_ghani@hotmail.com",
      link: "mailto:muhammadusman_ghani@hotmail.com",
      logo: "assets/images/client/google-logo.png",
    },
  ]

  projects = [
    {
      name: "The RD Link",
      description: "The nutrition expert directory for health & wellness brands.",
      link: "https://therdlink.com/",
      img: "assets/images/projects/RDLink.PNG",
    },
    {
      name: "Foodiary",
      description: "Personal nutrition coaching from Foodiary.",
      link: "https://foodiary.coach/",
      img: "assets/images/projects/foodiary-coach.PNG",
    },
    {
      name: "Ready Steady Movers",
      description: "We love helping people move, on time and on budget.",
      link: "http://readysteadymovers.co.uk/",
      img: "assets/images/projects/readysteadymovers.PNG",
    },
    {
      name: "Douxl",
      description: "We can fulfill your business needs.",
      link: "https://douxl.com/",
      img: "assets/images/projects/douxl.PNG",
    },
    {
      name: "ABC Home",
      description: "Shop all the pieces you need to complete your living room.",
      link: "https://abchome.com/",
      img: "assets/images/projects/abchome.png",
    },
    {
      name: "Linups",
      description: "Projected lineups can help give you the edge in all types of fantasy sports or sports betting.",
      link: "https://www.lineups.com/",
      img: "assets/images/projects/lineups.PNG",
    },
    {
      name: "RFP Gurus",
      description: "RFPGurus catalyzes your Government Bidding Prospects the Smart Way.",
      link: "https://www.rfpgurus.com/",
      img: "assets/images/projects/rfpgurus.PNG",
    },
    {
      name: "Edmonton Tel",
      description: "Deliver high quality equipment and increase services revenue.",
      link: "http://demo.edmontontel.ca/",
      img: "assets/images/projects/edmontel.PNG",
    },
    {
      name: "E-Commerce Zone",
      description: "Best site to buy electronics devices.",
      link: "https://demo.activeitzone.com/ecommerce/",
      img: "assets/images/projects/active-zone.PNG",
    },
    {
      name: "At Bye",
      description: "Best site to buy men's and women's things.",
      link: "https://atbye.com/",
      img: "assets/images/projects/atbye.PNG",
    },
  ]
}
